// api模块
import config from "@/common/config"
import tool from "@/common/tool"

const urls = {
  openid: '/api/openid',
  jopenid: '/wx/openid',
  jssign: '/api/jssign', // sisign
  jssdk: "/api/jssdk",
  bind: "/api/bind_wx_client",
  uploads: {
    file: '/api/uploaddata',
    base64: '/api/uploadbase64',
    // video:"https://voice.bzjt.medflying.com/ffmpeg/chat",
    // video: "/ffmpeg/chat",
    uploadWeixin: "/api/upload_wx_image"
  },
  // meetingStatus: "/background/meeting/status",
  // api: {
  //   pdf: '/api/manual_book', // 医生是 1 管理员 2
  //   getmeet: '/background/api/meet',
  //   screenhost: '/background/api/img',
  //   videoscreen: '/background/api/video',
  //   meetlist: '/background/api/nopic'
  // },
  admin: {
    code: '/admins/sms', // 验证码
    login: '/admins/login', // 登录
    detail: '/admins/detail', // 管理员详细信息
    list: '/admins/doctor_list', // 管理员医生列表
    doctorlist: '/admins/doctor_meeting_list', // 医生信息
    doctor: '/admins/doctor_info', // 医生信息
    updateDoctorSingle: '/admins/doctor_info_plus', // 更新医生某一单独的信息
    matchTimes:'/admins/match_times',//管理员智能匹配医生次数
    addMatchTimes:"/doctor/add_match_times",//增加管理员智能匹配医生次数
    addChangeDocTimes:'/admins/add_meeting_times',//增加变更医生次数
    freezeMeeting: '/admins/freeze_meeting',//变更医生或变更时间达到上限冻结会议
    getDoctorID: '/doctor/check_doctor',//获取本地库里的医生ID，本地库没有医生会新增
    freezeLists:"/admins/freeze_lists",//冻结会议列表
    unFreeze:"/admins/unfreeze",//解冻会议
    applyAudit:"/admins/apply_info",//申请人工审核医生ID卡等信息
    examineStatus:"/admins/examine_info",//获取人工审核状态
    courseware: '/admins/class', // 课件列表
    meeting: '/admins/meeting', // 会议创建
    sign: '/admins/sign', // 签署协议
    bindCourseware: "/doctor/meet_bind_class", //医生会议绑定课件
    cancers: "/api/cancer",// "获取癌肿" 
    //   haveCourseware:"/doctor/use_class",// "当前医生使用过的课件"
    haveCourseware: "/admins/use_class",// "当前医生使用过的课件"
    job: "/api/job", // 检查职称
  },
  doctor: {
    getAllDoctor: '/doctor/get_all_doctor',  // 所有医生
    getAllDoctorInfo: '/doctor/all_doctor_info',  // 根据医生总库库all_doctor_id医生信息
    doctorInfoByID: '/doctor/id_info', // 根据医生本地库ID查询医生信息
    info: '/doctor/info',  // 医生信息
    hospital: '/doctor/hospital', // 医院列表
    department: '/doctor/department', // 科室列表
    meeting: '/doctor/meeting', // 会议列表
    register: "/doctor/unreg",
    sign: "/doctor/sign",
    discardMeet: "/doctor/discard_meet"//会议作废
  },
  client: {
    sign: '/api/sign'
  },
}


let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
  if (typeof urls[key] == "object") {
    for (let i in urls[key]) {
      urls[key][i] = urls[key][i].includes("http") ? urls[key][i] : `${host}${config.urlPrefix}${urls[key][i]}`;
    }
    continue
  }
  urls[key] = `${host}${config.urlPrefix}${urls[key]}`;
}
export default urls;