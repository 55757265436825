import config from "@/common/config"
import axios from "@/common/axios"
import tool from "@/common/tool"
import urls from "@/common/urls"
import store from "@/store"
import wx from "@/common/weixin"


export const initWeixin = (to,from,next) => {
    let routeNames = ['docterdetail','live',"login"]
    if(routeNames.includes(to.name)){
      wx.init()
    }

}

export const liveRouteBefore = async (to,from,next) => {
  if(to.name == 'sign'){
    next();
    return;
  }
  let signInfo = store.state.common.signInfo;
  if(signInfo?.openid) { next(); return; }
  let url = tool.getURL(urls.client.sign,tool.getSystemInfo())
  let response = await axios.get(url)
  if(response.data && response.data.openid){
    store.dispatch("setItem",{signInfo:response.data})
    next()
  }else{
    next({
      path:`/sign`,
      query:to.query
    })
  }
}
export const adminRouteBefore = (to,from,next) => {
  if(to.name == 'login'){
    next();
    return;
  }
  let adminLoginData = tool.getLocalCookie("adminLoginData") || {}
  if (!adminLoginData.phone) {
    next("/login")
    return;
  }else{
    if(store.state.common.adminInfo){
      next()
      return;
    }
    axios.post(urls.admin.login,adminLoginData,{},true).then(res=>{
      store.dispatch("setItem",{adminInfo:res})
      tool.setLocalCookie('adminLoginData',adminLoginData)
      next()
    }).catch(err=>{
      next("/login")
    })
  }
  
}
export const doctorRouteBefore = async (to,from,next) => {
  next("/page404")
  return;
  if(to.name == 'information'){
    next();
    return;
  }
  let userInfo = store.state.common.userInfo;
  if(userInfo?.openid) { next(); return; }
  let url = `${urls.doctor.info}`
  let response = await axios.get(url)
  if(response.data && response.data.openid){
    store.dispatch("setItem",{userInfo:response.data})
    next()
  }else{
    next("/information")
  }
}