import axios from 'axios'
import tool  from '@/common/tool'
import config  from '@/common/config'
import tips  from '@/common/tips'
import store from '@/store'
const getHeaders = () =>{
  let adminData = store.state.common.adminInfo ||{}
  return  {
    openid:tool.getCookie('openid') || '',
    phone:adminData.phone || '',
  };
}
const tipCode = (err) => {
  let code = err.response.status;
  let errCode = config.tips.errCode
  console.log(code)
  try{
    if(code == 500){
      tips.error({text:errCode[500]})
    }else{
      tips.error({text:err.response.data.msg || errCode[code] || errCode.default})
    }
  }catch(e){
    console.log(err)
    tips.error({text:errCode.other})
  }
}
const download = (url,name,type,resType='blob') => {
  let downLoading = tips.loading()
  axios({
    method: 'get',
    headers: {
      'Content-Type': config.types.exports[type],
      ...getHeaders()
    },
    responseType: resType,
    url: url,
  }).then( res =>{
    
    let downloadURL = ''
    if(resType == 'json'){
      downloadURL = res.data.data.url
      window.location.href = downloadURL
      downLoading.close()
      return;
    }else{
      let blob = new Blob([res.data], {type: config.types.exports[type]});
      if('msSaveOrOpenBlob' in navigator){
        window.navigator.msSaveOrOpenBlob(blob, `${name}.${type}`);
        downLoading.close()
        return
      }                 
      downloadURL = window.URL.createObjectURL(blob);    
    }
    let downloadElement = document.createElement('a');              
    downloadElement.href = downloadURL;
    downloadElement.download = `${name}.${type}`;
    document.body.appendChild(downloadElement);                  
    downloadElement.click();                  
    document.body.removeChild(downloadElement);                  
    window.URL.revokeObjectURL(downloadURL);
    downLoading.close()
    // callback && callback() 
  }).catch( () => {
    tips.error({text:'暂没有数据阔以导出'});
    downLoading.close()
    // callback && callback() 
  });
}
const AxiosGet =  async (url,headers,isTip=false) =>{
  return AxiosRequest(url,{},'get',headers,isTip)
}
const AxiosPost =  async (url,data,headers,isTip=false) =>{
  return  AxiosRequest(url,data,'post',headers,isTip)
}
const AxiosStream = async (url, data, headers,isTip=false,needHeader = false) => {
  return AxiosRequest(url, data, 'post', headers,isTip,needHeader)
}
const AxiosDelete =  async (url,headers,isTip=false) =>{
  return  AxiosRequest(url,{},'delete',headers,isTip)
}
const AxiosPatch =  async (url,data,headers,isTip=false) =>{
  return  AxiosRequest(url,data,'patch',headers,isTip)
}

const AxiosRequest =  async (url,data={},method,headers={},isTip=false,needHeader = true) => {
  if(needHeader){
    headers = {
      ...getHeaders(),
      ...headers
    }
  }
  return new Promise((resolve,reject)=>{
    axios({
      method,
      url,
      data,
      processData: false,  // 不处理数据
      contentType: false,   // 不设置内容类型
      headers:headers
    }).then((res)=>{
      resolve(res.data)
    }).catch((err)=>{
      if(isTip){
        //admin/docterdetail.vue下doCommitSingle方法有用到，需resolve返回数据
        resolve({errcode:true,data:null,err:err})
        return
      }
      try{
        let data = err.response.data;
        if(data.detail) console.log(err.response)
        else tipCode(err);
        reject({errcode:true,data:null,err:err})
      }catch(e){
        reject({errcode:true,data:null,err:err})
      }
    })
  })

}

export default {
  get:AxiosGet,
  post:AxiosPost,
  delete:AxiosDelete,
  patch:AxiosPatch,
  stream:AxiosStream,
  download,
  getHeaders
}