export default {
    tips:{
      errCode:{
        400:"参数错误",
        403:"权限受限，请联系管理员",
        404:"请求链接不存在,请联系管理员",
        405:"请求方式不正确,应为POST/GET",
        500:"服务器错误,请联系管理员",
        default:"无效的请求,状态码($),请联系管理员",
        other:"未知错误，请联系管理排查"
      }
    },
    // branch:"dev",
    // localhost:"kapi",
    localhost:"https://bzjt.medflying.com",
    urlPrefix:"/ui", // 正式服记得改
    prefix:"", // 正式服记得改
    host:"https://bzjt.medflying.com",
    logo:"https://bzjt.medflying.com/app/images/login/icon.png",
    websocketHost:"wss://bzjt.medflying.com/ws",// 正式服记得改
    types:{
      exports:{
        zip:'application/zip',
        xlsx:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      },
      pays:{
        0:'未付款',
        10:'会议审核中 ',
        20:'会议资料整理中',
        25:"付款不成功",
        30:'基金会审核中',
        35:'付款失败更新返回基金会审核中',
        40:'付款成功',
      }
    },
    auth:[0,1], // 观众,管理员
    maxPlayTimes:30, // 最大播放时间
    spaceTime:7, // 提示间隔时间
    popups:{
      infloor:'进入课堂',
      outfloor:'离开课堂',
      academic:'教授',
      video:['课程即将开始','课程即将开始，专家准备中','课程即将开始，请大家认真学习'],
      endVideo:'课程已经开始，请点击视频区播放按钮',
      startVideo:'课程即将开始，请大家稍候片刻',
      startMeet:'会议正式开始，请主持专家致开场词',
      phase:{
        0:{ 
          content:['请选择会议类型'],
          button:['测试会议','正式会议']
        },
        1:{ 
          content:['请进行开场致辞'],
          button:['确认']
        },
        2:{ 
          content:['是否开始播放课件'],
          button:['确认']
        },
        3:{ 
          content:['请开始讨论',"(需至少10分钟)"],
          style:["",{color:"#008f3d"}],
          button:['确认']
        },
        endMeeting: { 
          content:["讨论时间已达到10分钟，是否结束？"],
          button:['确认']
        },
        discuss:{
          content:["课件还有1分钟播放完毕。即将进入讨论环节，讨论时间需至少10分钟，请知晓。"],
          button:['确认']
        },
        connect:{
          content:['您的网络已中断，请切换至稳定的网络后，点击“重新连接”'],
          button:['重新链接']
        },
        welcome:{
          title:"声明",
          content:['百展讲堂旨在为公众和医疗卫生专业人士提供便捷的方式获取和了解关于肿瘤治疗的专业知识，由于科学研究和临床实践的不断发展与充实，医学知识日益更新，专家的见解也可能存在差异，本平台所涵内容并非旨在提供专业意见,仅供交流与参考，如果您想了解更多本平台中出现的与疾病或药物有关的信息，请务必咨询医疗卫生专业人士。'],
          button:['进入互动课堂']
        }
      },
    },
    meeting:{
      start:60,
      discuss:10*60
    },
    search:{
      pagesize:10,
      page:1
    },
    color:"#008f3d",
    wxinterface:[
      // "startRecord",
      // "stopRecord",
      // "onVoiceRecordEnd",
      // "playVoice",
      // "pauseVoice",
      // "stopVoice",
      // "onVoicePlayEnd",
      // "uploadVoice",
      // "downloadVoice",
      "chooseImage",
      "uploadImage",
      "closeWindow",
      "scanQRCode",
      "updateAppMessageShareData",
      "onMenuShareAppMessage",
      "onMenuShareTimeline",
      "updateTimelineShareData",
  ]

}