import { createRouter, createWebHashHistory } from "vue-router"
// import weixin from "@/common/weixin"
import tool from "@/common/tool"
import { liveRouteBefore, adminRouteBefore, doctorRouteBefore, initWeixin } from './routerBeforeEnter'
import axios from "@/common/axios"
import urls from "@/common/urls"
import config from "@/common/config"
import store from '@/store'

const adminRoutes = [
  {
    path: "/login",
    name: "login",
    meta: {
      title: '登录',
      keepAlive: false,
    },
    beforeEnter: adminRouteBefore,
    component: () => import(/* webpackChunkName: "login", webpackPrefetch: true */ "@/components/page/user/login.vue")
  },
  {
    path: "/adminmeet",
    name: "adminmeet",
    meta: {
      title: '主页',
      keepAlive: false,
    },
    beforeEnter: adminRouteBefore,
    component: () => import(/* webpackChunkName: "adminmeet", webpackPrefetch: true */ "@/components/page/admin/adminmeet.vue")
  },
  {
    path: "/doctermeet",
    name: "doctermeet",
    meta: {
      title: '医生会议列表',
      keepAlive: false,
    },
    beforeEnter: adminRouteBefore,
    component: () => import(/* webpackChunkName: "doctermeet", webpackPrefetch: true */ "@/components/page/admin/doctermeet.vue")
  },
  {
    path: "/docterdetail",
    name: "docterdetail",
    meta: {
      title: '专家信息',
      keepAlive: false,
    },
    beforeEnter: adminRouteBefore,
    component: () => import(/* webpackChunkName: "docterdetail", webpackPrefetch: true */ "@/components/page/admin/docterdetail.vue")
  },
  {
    path: "/createdocter",
    name: "createdocter",
    meta: {
      title: '专家信息',
      keepAlive: false,
    },
    beforeEnter: adminRouteBefore,
    component: () => import(/* webpackChunkName: "createdocter", webpackPrefetch: true */ "@/components/page/admin/createdocter.vue")
  },
  {
    path: "/coursewares",
    name: "coursewares",
    meta: {
      title: '肿瘤抗血管治疗新进展课件库',
      keepAlive: true,
    },
    component: () => import(/* webpackChunkName: "coursewares", webpackPrefetch: true */ "@/components/page/admin/coursewares.vue")
  },
  {
    path: "/preview",
    name: "preview",
    meta: {
      title: '课件预览',
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "preview", webpackPrefetch: true */ "@/components/page/admin/preview.vue")
  },
  {
    path: "/meetInfo",
    name: "meetInfo",
    meta: {
      title: '',
      keepAlive: false,
    },
    beforeEnter: adminRouteBefore,
    component: () => import(/* webpackChunkName: "meetInfo", webpackPrefetch: true */ "@/components/page/admin/meetinfo.vue")
  },
  {
    path: "/freezeMeetintList",
    name: "freezeMeetintList",
    meta: {
      title: '冻结会议列表',
      keepAlive: false,
    },
    beforeEnter: adminRouteBefore,
    component: () => import(/* webpackChunkName: "freezeMeetintList", webpackPrefetch: true */ "@/components/page/admin/freezeMeetintList.vue")
  },
]

const doctorRoutes = [
  {
    path: "/information",
    name: "information",
    meta: {
      title: '注册申请',
      keepAlive: false,
    },
    beforeEnter: doctorRouteBefore,
    component: () => import(/* webpackChunkName: "information", webpackPrefetch: true */ "@/components/page/user/information.vue")
  },
  {
    path: "/meetDetail",
    name: "meetDetail",
    meta: {
      title: '会议新增',
      keepAlive: false,
    },
    beforeEnter: doctorRouteBefore,
    component: () => import(/* webpackChunkName: "meetDetail", webpackPrefetch: true */ "@/components/page/admin/meetDetail.vue")
  },
  {
    path: "/meetlist",
    name: "meetlist",
    meta: {
      title: '会议列表',
      keepAlive: false,
    },
    beforeEnter: doctorRouteBefore,
    component: () => import(/* webpackChunkName: "meetlist", webpackPrefetch: true */ "@/components/page/doctor/meetlist.vue")
  },
  {
    path: "/notebook",
    name: "notebook",
    meta: {
      title: '手册',
      keepAlive: false,
    },
    beforeEnter: doctorRouteBefore,
    component: () => import(/* webpackChunkName: "notebook", webpackPrefetch: true */ "@/components/page/doctor/notebook.vue")
  },
  {
    path: "/agreement",
    name: "agreement",
    meta: {
      title: '协议签署',
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "agreement", webpackPrefetch: true */ "@/components/page/doctor/agreement.vue")
  },
]
const liveRoutes = [
  {
    path: "/sign",
    name: "sign",
    meta: {
      title: '签到',
      keepAlive: false,
    },
    beforeEnter: liveRouteBefore,
    component: () => import(/* webpackChunkName: "sign", webpackPrefetch: true */ "@/components/page/user/sign.vue")
  },
  {
    path: "/live",
    name: "live",
    meta: {
      title: '会议',
      keepAlive: false,
    },
    beforeEnter: liveRouteBefore,
    component: () => import(/* webpackChunkName: "live", webpackPrefetch: true */ "@/components/page/live/live.vue")
  },
  {
    path: "/statement",
    name: "statement",
    meta: {
      title: '声明',
      keepAlive: false,
    },
    component: () => import(/* webpackChunkName: "statement", webpackPrefetch: true */ "@/components/page/live/statement.vue")
  },
]


const routes = [
  ...adminRoutes,
  ...doctorRoutes,
  ...liveRoutes,
  {
    path: "/page404",
    name: "page404",
    component: () => import(/* webpackChunkName: "page404", webpackPrefetch: true */ "@/components/common/page404.vue")
  },
  {
    path: "/jump",
    name: "jump",
    component: () => import(/* webpackChunkName: "jump", webpackPrefetch: true */ "@/components/main/jump.vue")
  },
  {
    path: "/mini",
    name: "mini",
    component: () => import(/* webpackChunkName: "mini", webpackPrefetch: true */ "@/components/page/user/mini.vue")
  }
]

const jumps = ['jump']
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (jumps.includes(to.name)) {
    next()
    return;
  }
  window.document.title = to.meta?.title || '';
  // 初始化微信对象 
  tool.isLocal() ? development(to, from, next) : produce(to, from, next);
})
async function development(to, from, next) {
  initWeixin(to);
  // tool.setCookie("openid", "oLND5515I68RMqO-izGfndIsaw-0")//先东
  tool.setCookie("openid", "oLND558p3uw0Po-chvommEAZ4JKw")//相菊
  // tool.setCookie("openid","oLND550ZhlV3stRauLKhmb8ei22U")//昌权
  inSystem(to, from, next)
}
async function produce(to, from, next) {
  initWeixin(to);
  let openid = tool.getCookie("openid")
  if (!openid) {
    next({ name: "jump", path: "/jump", query: { path: to.path, ...to.query } })
    return;
  }
  inSystem(to, from, next)
}
async function inSystem(to, from, next) {
  let route = routes.find(item => item.name == to.name)
  if (!route) {
    next("/page404")
  }
  let adminItem = adminRoutes.find(r=>r.name==to.name)
  if(adminItem && to.name != "login"){
    isLogin(to,from,next)
    return;
  }
  next()
}

async function isLogin(to, from, next) {
  let adminInfo = store.state.common.adminInfo || {};
  let adminLoginData = tool.getLocalCookie("adminLoginData") || {}
  if (!adminLoginData.phone) {
    next("/login")
    return;
  }
  if (!adminInfo.phone && adminLoginData.phone) {
    axios.post(urls.admin.login, adminLoginData).then(res => {
      store.dispatch("setItem", { adminInfo: res })
      tool.setLocalCookie('adminLoginData', adminLoginData)
      next()
    }).catch(err => {
      next("/login")
    })
    return;
  }
  tool.setLocalCookie('adminLoginData', adminLoginData)
  next();
}

export default router;